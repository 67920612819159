export class CouponCodeBannerController {

  static $inject = [
    "AppConfig",
    "AppSettings",
    "$rootScope",
    "$window",
    "$element",
    "$translate",
  ];

  couponMessage: string = "";
  isSticky: boolean = false;

  constructor(
    private appConfig: any,
    private appSettings: any,
    private $rootScope: any,
    private $window: any,
    private $element: any,
    private $translate: any,
  ) {
    const banners = this.appConfig.banners;
    this.couponMessage = this.getActiveBanner(banners);
    angular.element($window).on("scroll", () => this.handleScroll());

    // Add watch for product type changes
    this.$rootScope.$watch(
      () => this.$rootScope.globalState.productType,
      () => {
        this.couponMessage = this.getActiveBanner(this.appConfig.banners);
      },
    );

    this.$rootScope.$watch("selectedLocale", () => {
      this.couponMessage = this.getActiveBanner(this.appConfig.banners);
    });
  }

  $onDestroy() {
    angular.element(this.$window).off("scroll");
  }

  private handleScroll() {
    const element = this.$element[0];
    const rect = element.getBoundingClientRect();
    this.isSticky = rect.top <= 0;
    this.$rootScope.$apply();
  }

  private getActiveBanner(banners: any[]): string {
    const now = new Date();
    const productType = this.$rootScope.globalState.productType;
    const currentLandingPage = this.appSettings.landingPageUrl[productType];

    const activeBanners = banners
      .filter(banner => {
        const activeDate = new Date(banner.active_date);
        const endDate = banner.end_date ? new Date(banner.end_date) : null;
        const matchesLandingPage = banner.landing_page_url === currentLandingPage;

        return activeDate <= now && (!endDate || endDate > now) && matchesLandingPage;
      })
      .sort((a, b) => new Date(b.active_date).getTime() - new Date(a.active_date).getTime());

    const textContent = this.$translate.instant(activeBanners[0]?.text_content);

    return textContent || "";
  }
}
