require('./trustyou.service')
require('./result_filters/reviews-filter-service')
require('./result_filters/redeem-points-filter-service')
require('./business-filters.service')
require('./format-service')
require('./tracker')
require('../services/currencies.service')
require('../utils/user-agent-checker')

angular.module('BookingApp')
.service "SearchFiltersService", [

  "$rootScope", "$filter", "$timeout", "TrustyouService", "ReviewsFilterService",
  "RedeemPointsFilterService", "BusinessFiltersService", "FormatService", "RzSliderOptions",
  "TrackerService", "AppSettings", "CurrenciesService", "UserAgentChecker"

  ($rootScope, $filter, $timeout, TrustyouService, ReviewsFilterService,
  RedeemPointsFilterService, BusinessFiltersService, FormatService, RzSliderOptions,
  TrackerService, AppSettings, CurrenciesService, UserAgentChecker) ->

    RzSliderOptions.options( { precision: 2, step: 0.001 } )

    self = this

    default_ratings_options = { # old design star ratings
      any:   0
      star2: 2
      star3: 3
      star4: 4
      star5: 5
    }

    default_v2StarRatings = {
      star1 : false
      star2 : false
      star3 : false
      star4 : false
      star5 : false
    }

    default_query = {name: ''}
    default_qprices = { minPrice: 0, maxPrice: 1000000 }
    default_qprice_options = {
                      low: {min: 0, max: 100, active: false},
                      mid_low: {min: 100, max: 200, active: false},
                      mid_high: {min: 200, max: 350, active: false},
                      high: {min: 500, max: 10000, active: false}
                      }
    default_starratings = [null, true, true, true, true, true] #used for mobile
    default_hotel_counters = { all: 0, displayed: 0, available: 0 }

    this.lowestHotelPrice = 1000000
    this.highestHotelPrice = 0

    this.priceFilterApplied = false

    this.query = angular.copy(default_query)
    this.selectedRatings = []
    this.qprices = angular.copy(default_qprices)
    this.qpriceOptions = angular.copy(default_qprice_options)
    this.qratings = 0
    this.starratings = angular.copy(default_starratings)
    this.voucherType = 0
    this.ratingScores = Object.keys default_ratings_options # old design star scores
    this.ratingsOptions = angular.copy(default_ratings_options) # old design star scores
    this.v2StarRatings = angular.copy(default_v2StarRatings)

    this.hotelCounters = angular.copy(default_hotel_counters)

    this.redeem_points = RedeemPointsFilterService
    this.reviews = ReviewsFilterService

    this.v2PriceSlider = {
      min: self.qprices.minPrice
      max: self.qprices.maxPrice
      options: {
        floor: self.lowestHotelPrice
        ceil: self.highestHotelPrice
        translate: (value) ->
          if $rootScope.selectedCurrency
            return $rootScope.selectedCurrency.symbol + self.formatPrice(value)
          else
            return self.formatPrice(value)
      }
    }
    SLIDER_STEP = 5

    priceSliderTimeout = null
    updatePrices = ->
      $timeout.cancel(priceSliderTimeout) if priceSliderTimeout
      self.qprices.minPrice = self.v2PriceSlider.min
      self.qprices.maxPrice = self.v2PriceSlider.max
      self.adjustSlider()
      priceSliderTimeout = $timeout( ->
        $rootScope.$broadcast('kaligoFilterSearchResult')
        priceSliderTimeout = null
      , 200)
      return

    this.queryFn = (hotel) ->
      queryName = self.query?.name?.toLowerCase()

      hotel.name.toLowerCase().indexOf(queryName) >= 0 ||
      hotel.original_metadata.name?.toLowerCase()?.indexOf(queryName) >= 0

    this.updatePriceSlider = () ->
      self.v2PriceSlider = {
        min: self.qprices.minPrice
        max: self.qprices.maxPrice
        options: {
          floor: self.lowestHotelPrice
          ceil: self.highestHotelPrice
          onChange: updatePrices
          step: 1 # initial step is 1 for accurate max value
          precision: 5
          translate: (value) ->
            return $rootScope.selectedCurrency.symbol + self.formatPrice(value)
        }
      }
      $timeout( ->
        $rootScope.$broadcast('rzSliderForceRender')
      , 50)
      return

    this.reset = () ->
      angular.copy(default_query, self.query)
      angular.copy(default_qprices, self.qprices)
      angular.copy(default_starratings, self.starratings)
      angular.copy(default_v2StarRatings, self.v2StarRatings)
      self.selectedRatings = []
      self.qratings = 0 if self.qratings != 0
      self.voucherType = 0 if self.voucherType != 0
      self.priceFilterApplied = false

      BusinessFiltersService.reset()
      RedeemPointsFilterService.reset()
      ReviewsFilterService.reset()
      return

    this.resetPriceRange = () ->
      self.qprices.minPrice = self.lowestHotelPrice
      self.qprices.maxPrice = self.highestHotelPrice
      for k,priceRangeOption of self.qpriceOptions
        priceRangeOption.active = false

    this.setSearchCeilingPrice = (searchCeiling) ->
      self.highestHotelPrice = searchCeiling
      self.qprices.maxPrice = searchCeiling unless self.priceFilterApplied

    this.setSearchBottomPrice = (searchBottom) ->
      self.lowestHotelPrice = searchBottom
      self.qprices.minPrice = searchBottom unless self.priceFilterApplied

    absoluteDifference = (a, b) ->
      Math.abs(a - b)

    this.adjustSlider = () -> #slider deals with whole numbers, search ceiling & bottom stored as float
      diff_to_max = absoluteDifference(self.qprices.maxPrice, self.highestHotelPrice)

      if diff_to_max >= SLIDER_STEP
        self.v2PriceSlider.options.step = SLIDER_STEP
      else
        self.v2PriceSlider.options.step = diff_to_max

      self.priceFilterApplied = (
        absoluteDifference(self.qprices.minPrice, self.lowestHotelPrice) > 1 ||
        diff_to_max > 1
      )

    this.fireFilteringEvent = ->
      TrackerService.filteringEvent(
        minPrice: Math.round(self.qprices.minPrice)
        maxPrice: Math.round(self.qprices.maxPrice)
        hotelName: self.query.name
      )

    this.filterByPriceRange = (hotel_records) ->
      if !self.priceFilterApplied
        return hotel_records

      results = []
      min = self.qprices.minPrice - 1
      max = self.qprices.maxPrice + 1

      for node in hotel_records
        nodePrice = if (node.price) then parseFloat(node.price) else 0
        results.push node if (nodePrice >= min and nodePrice <= max) && nodePrice > 0

      return results

    this.setPriceRangeOptions = () ->
      return unless UserAgentChecker.isIE11()
      priceFraction = (self.highestHotelPrice - self.lowestHotelPrice)/4
      i = 0
      for k,priceRangeOption of self.qpriceOptions
        priceRangeOption.min = priceFraction*i
        i++
        priceRangeOption.max = priceFraction*i

    @toggleRating = (rating) ->
      if rating in self.selectedRatings
        self.selectedRatings = (x for x in self.selectedRatings when x != rating)
      else
        self.selectedRatings.push(rating)
      self.selectedRatings

    @toggleQuickFilter = () ->
      $rootScope.$broadcast('kaligoFilterSearchResult')

    this.filterByStarRatings = (hotel_records) ->
      return hotel_records if self.selectedRatings.length == 0
      (hotel for hotel in hotel_records when parseInt(hotel.rating) in self.selectedRatings)

    this.filterByOldStarRatings = (hotel_records) -> # old design star rating
      return hotel_records if self.qratings == 0
      (hotel for hotel in hotel_records when parseFloat(hotel.rating) >= self.qratings)

    this.filterUnavailableHotels = (hotel_records) ->
      (hotel for hotel in hotel_records when hotel.available)

    this.filterByHotelVoucherType = (hotel_records) ->
      voucherTypeInt = parseInt(self.voucherType)
      (hotel for hotel in hotel_records when hotel.voucher_type_id == voucherTypeInt)

    this.countAvailableHotels = (hotel_records) ->
      available_hotels = 0
      (available_hotels += 1 for hotel in hotel_records when hotel.available)
      self.hotelCounters.available = available_hotels

    this.formatPrice = (price) ->
      if !$rootScope.globalState.showTotalNights
        price = price / $rootScope.durationExcludingComplimentaryNights
        price = price / $rootScope.roomCount
      price = CurrenciesService.convertFromUsd(price)
      FormatService.number(price, $rootScope.selectedLocale, 0)

    this.formattedPrice = () ->
      currencySymbol = $rootScope.selectedCurrency.symbol
      formattedMinPrice = currencySymbol + self.formatPrice(self.v2PriceSlider.min)
      formattedMaxPrice = currencySymbol + self.formatPrice(self.v2PriceSlider.max)
      return "#{formattedMinPrice} - #{formattedMaxPrice}"

    # Reads AppSettings ratingArray and uses that value to pre filter hotels
    this.preFilterByStarRating = (hotel_records) ->
      (hotel for hotel in hotel_records when parseInt(hotel.rating) in AppSettings.ratingArray)

    return this
]
