import {
  CarDetailsTemplateConfig, ContactUsConfig, FlightSortType, HeaderUrl, HermesConfig,
  HotelDetailsTemplateConfig, LinkTargetType, LoginModalConfig, LoginModalSizeOption,
  LoginModalWindowClassOption, MobileResultPopupConfig, MomentDateType,
  OpenInTargetTabType, PaymentMethod, PointsCashSliderSettings,
  ProductType, RCTenant, SessionTimerConfig, Tenant, TravelType,
} from "booking_app/types";

export interface AppSettings {
  // Display or hide book with confidence panel in checkout
  // First introduced by Loylogic
  displayBookWithConfidence: boolean;

  // Customize payment button in checkout
  // First introduced by Loylogic
  customCheckoutButtonImage: string;

  // Customize payment loading image in checkout
  // First introduced by Loylogic
  customPaymentLoadingImage: string;

  // Checkout VAT information
  // First introduced by EL AL
  showVatInfo: boolean;

  // Customize cars result list item button label
  // First introduced by Loylogic
  carsListBtnLabel: string;

  // In checkout page, show visa logo only
  // First introduced by Ping An
  onlyShowVisaCardLogo: boolean;

  // In checkout page, use the new powered by kaligo travel solutions image
  // First introduced by El Al
  poweredByAscenda: boolean;

  // In checkout page, display diners payment option
  // First introduced by EL AL
  displayDinersOptions: boolean;

  // In checkout page, display custom payment logo in credit card payment box
  // First introduced by EL AL
  customPaymentLogo: string;

  // To show count of all of the static data instead of the one returned by server
  // Default for kaligo and other sites is to show all static data count for the destination
  // First introduced by Loylogic
  includeUnavailableInTotalCount: boolean;

  // Show per night text on results page
  // First introduced by Velocity
  showPerNightText: boolean;

  payWithPoints: {
    // Round points to nearest
    // First introduced by EL AL
    roundToNearest: number;

    roundToNearestByCouponCodeSettings?: {
      hotels?: {
        earn?: number,
        redeem?: number,
      },
      cars?: {
        earn?: number,
        redeem?: number,
      }
    }


    // For instances where round to is different for each travel type
    roundToNearestByTravelType: {
      hotels?: number,
      cars?: number,
      flights?: number,
    };

    // Show earned points on cash value used in redemption product
    // First introduced by Velocity
    earnOnBurn: boolean;

    // How points are rounded, this should match the tenant's redemption settings
    // First introduced by BNZ
    roundDirection: "up" | "down";
  };

  // Use custom loading search partial on search and details page
  // First introduced by EL AL
  customLoadingSearch: boolean;

  // Contain hotel details template configs
  hotelDetailsTemplateConfig: HotelDetailsTemplateConfig;

    // Contain car details template configs
  carDetailsTemplateConfig: CarDetailsTemplateConfig;

  // ELAL wants to hide button and replace with manual cancellation for redeem
  // First introduced by EL AL
  turnOnManualCancellation: boolean;

  // Allow cancelling redemption bookings
  allowCancelRedemptionBooking: boolean;

  // Display cancellation policy on hotel redemption
  // and this will show cancellation policy penalty in percentage
  // First introduced in Loylgic and EL AL
  showCancelPolicyOnRedeem: boolean;

  // Display cancellation policy on hotel earn
  // Needed to always show non refundable on VISA
  showCancelPolicyOnEarn: boolean;

  // Display non refundable cancellation policy on cars accrual and redemption
  carNonRefundablePolicy: boolean;

  // on earn, display a badge that returns 2x or 3x more points for a particular hotel listing
  // First introduced by EL AL
  displayBestDealBadge: boolean;

  // benchmark to dictate if the deal returns a multiplier of 2x or 3x more points.
  // multiplier = (Price per night / elalMultiplierValue) in USD
  // First introduced by EL AL
  basePointsRatio: number;

  // on earn, display non-bookable compare rates.
  // First introduced by Entertainer
  displayNonBookableMarketRates: boolean;

  // In details and checkout page, disables 'Book Now' button if insufficient points
  // First introduced by Velocity
  insufficientPointsCheck: boolean;

  // Displays cancellation policy message on hotel details page
  // First introduced by EL AL
  policyNoteOnDetailsPage: string;

  // Need to check if user is still logged in
  // First introduced by EL AL
  checkUserStillLoggedin: boolean;

  // Filter out locales
  // Default value (for Kaligo.com and most WLs) is Hebrew
  // First introduced by EL AL
  restrictedLocales: string[];

  // Force browser to reload the page on search
  // Used on whitelabel that have multiple product type : earn/redeem/voucher
  reloadOnSearch: boolean;

  // Force browser to reload the page on quick search
  // Used on whitelabel that have multiple product type : earn/redeem/voucher with quick toggle
  reloadOnQuickSearch: boolean;

  // Hide cars checkout payment section
  // First introduced by LL
  hideCarsCheckoutPayment: boolean;

  // Custom booking option tabs in hotel details
  // First introduced by Entertainer
  customBookingOptionTabs: boolean;

  // Show minimap up front on hotel-details pg
  // First introduced by Entertainer
  showMiniMap: boolean;

  // Number of hotels per page
  // Introduced by Kaligo to remove configFactory
  hotelsPerPage: number;

  // In checkout page, display Discover logo
  // First introduced by Entertainer
  displayDiscoverLogo: boolean;

  // In checkout page, display JCB logo
  // First introduced by Entertainer
  displayJcbLogo: boolean;

  // In checkout page, display Diners logo
  // First introduced by Entertainer
  displayDinersLogo: boolean;

  // Show "Gulf Destination Alert" modal
  // First introduced by Jal
  showGulfAlert: boolean;

  // To allow user to select dates within minBookingDays
  // First introduced by Visa
  enableSelectionOfDates: boolean;

  // In checkout page, check supported currencies for credit cards
  // First introduced by Entertainer
  supportedCards: {
    "amex": string[];
    "discover": string[];
    "diners": string[];
    "elo": string[];
    "aura": string[];
    "hipercard": string[];
    "jcb": string[];
  };

  // Mapbox API key, moved out from MapboxService
  mapboxAPIKey: string;

  // Show market rates for earn landing page or not
  showMarketRates: boolean;

  // Enable children in hotel search form when using the default template
  // First introduced by Kaligo.com
  enableChildrenSearch: boolean;

  // Provide customisable children age list per whitelabel. Default is 0..17
  // First introduced by TurkishAirlines whitelabel
  childrenAgeList: number[];

  // Temporary settings to redirect to either /purchases or /bookings for checkout pages
  // Will be deprecated once all purchases page are moved to AngularJS app
  checkoutRedirectToBookingsPath: boolean;

  // Set userGulfResident as AE by default, only EL AL will be IL
  // First introduced by EL AL
  userGulfResident: string;

  // Set visibility of points partner icon next to room points value
  pointsPartnerIconVisibility: boolean;

  // Session timeout popup configurations
  // Include whitelabels settings in their own controllers
  sessionCounter: SessionTimerConfig;

  // US date format formatting  MM / DD / YYYY
  usDateFormat: boolean;

  // Determines if the access and cancel email text is displayed in #successSubTitle
  displayPurchaseAccessAndCancelText: boolean;

  // Array of star ratings to be displayed in the filter
  // First introduced by VISA to customize star rating filter
  ratingArray: number[];

  // Determines whether hotel results page pre filters unavailable hotels
  // First introduced by VISA to completely hide unavailable hotels
  preFilterUnavailableHotels: boolean;

  // Introduced by Visa and JAL for placeholder texts in the checkout page
  checkoutPagePlaceholderText: {
    firstName: string;
    lastName: string;
  };

  // First Introduced for VISA to block redirect to external suppliers
  // The purpose of this is to make sure that the user wont be redirected to the
  // market rate booking url when the comp rates are pressed.
  // you can see the changes related to this on this file hotel-list-item/comp-rates/comp-rates.controller.ts
  redirectMarketRates: boolean;

  // First Introduced for VISA to hide cash slider
  // Purpose of this is to have a settings to hide the cash slider for the filters
  // File related that handle this config is kaligo-select-controller.js.coffee
  showCashSlider: boolean;

  // First Introduced for JAL to make sure we can access the landing page urls inside
  // Purpose of this is for us to prevent usage of multiple intances of const using landingPageUrl
  landingPageUrl: {
    "earn"?: string;
    "redeem"?: string;
    "voucher"?: string;
    "complimentary-nights"?: string;
  };
  // First Introduced for JAl to change the color of Hotel markers in mapbox
  mapboxHotelMarkerColor: string;
  // First Introduced for JAl to change the color of Hotel cluster markers in mapbox
  mapboxHotelClusterColor: string;
  // First Introduced for JAl to change the color of Hotel cluster count in mapbox
  mapboxHotelClusterCounterTextColor: string;

  // First indtroduced for jal to restrict the user from booking beyond maxmium available months
  maximumAvailableBookingMonths: number;

  // Google analytics uid
  gauid: string;

  // Google analytics displayFeatures
  displayFeatures: boolean;

  // First Introduced for VMORC Image processing task
  // this will hold the cdn URL for processing big images
  cdnProcessingURL: string;

  // Introduced by Kaligo.com with regards the migration of the hotel details page to new template
  // This setting will determine the controls for the colors of the the button and backgroundBG
  // and is based on the its bg color setting
  settingBasedColor: boolean;

  // Introduced for Visa in guest-room-counter component. To show a message if the adult count is > 4
  maximumGuestsMessage: {
    showMessage: boolean;
    maximumGuests: number;
  };

  // This is a switch to control how WLs are going to use product type. If set to true ,
  // WL will use the de-coupled product type. First introduced in ACME
  useProductType: boolean;

  // This setting needs to be overridden when a WL use product-switch component.
  // First introduced in ACME
  supportedProductTypes: ProductType[];

  // Introduced on OCBC room guest counter
  roomGuestParameters: {
    labelOnlyRoomCounter: boolean;
    hasRoomGuestCloser: boolean;
    labelOnlySummaryText: boolean;
  };

  // Introduced on OCBC to have a customised search destination placeholder
  destinationFieldPlaceHolder: string;
  // Store credit card for user with stripe customer
  storeCreditCard: boolean;

  // Introduced for OCBC to hide the currency denomination in the redemption range filter
  showCurrencyInFilterSliders: boolean;

  // Introduced on UOB to move the city json call to a service
  limitedCountryCityOptions: boolean;

  // Show coupon-code UI in the front-end. First introduced in kaligo
  hasCouponCode: boolean;

  // Introduced on OCBC flights to be able to customised the flight icon
  customFlightIcon: boolean;

  // Introduced on OCBC flights list item to control whether we show the flight selection state status
  showFlightSelectionState: boolean;

  // Introduced on OCBC flights segments to hide or display arrival date
  // Refactor soon once we have an increased number of configs for this part
  flightSegmentArrivalDisplay: boolean;

  // Use multiple payment method UI. Introduced first in OCBC
  hasMultiplePaymentMethods: boolean;

  // Introduced on OCBC to update and change the default airline on search
  defaultAirlineSearchCode: string;

  // This is to make sure we can configure timeout and promptTimeout for hermes
  hermesConfig: HermesConfig;

  // Logout url specific to each whitelabel
  logoutUrl: string;

  // In datepicker , user can select dates of previous and next months
  canSelectDaysOfPreviousAndNextMonth: boolean;

  // Default Payment Method
  defaultPaymentMethod: PaymentMethod;

  // Introduced on FAB flights to customize summary text header
  hasCustomflightSummaryHeaderText: boolean;

  // Introduced on FAB flights to be able to customised the line icon
  customFlightLineIconUrl: string;

  // Introduced on FAB 443 Date Icon replacing default calendar with waxing crescent
  dateIcon: string;

  // Indroduced in FAB, OCBC to allow more sorting options for flights
  flightsEnabledSortingOptions: FlightSortType[];

  // Introduced as part of new GlobalStateService.
  defaultTravelTypeInHomePage: TravelType;

  // Introduced in FAB
  defaultCountryInCheckout: {
    hasDefaultCountry: boolean;
    defaultCountryCode: string;
  };

  // Introduced on FAB to support SVG icons.
  iconFormat: string;

  // Common login modal window configurations
  loginModalConfig: LoginModalConfig;

  // Contact Us Page Configurations
  contactUsConfig: ContactUsConfig;

  // Identify current tenant
  tenant: Tenant | RCTenant;

  // Identify current tenant full name
  tenantFullName: string;

  // Introduced in OCBC
  requireLoginOnConfirmationPage: boolean;

  // Checkout form scroll to invalid fields offset.
  // This is different for some WLs which has bigger header
  checkoutFormScrollOffset: number;

  // results page scroll offset
  resultsPageScrollOffset: {
    desktop: number;
    mobile?: number;
  };

  // Configurations for custom icon of Filter, Sort, Map in mobile results view
  mobileResultPopupConfig: MobileResultPopupConfig;

  // WL specific date format which can be be passed to moment object
  dateFormat: MomentDateType;

  // Mobile First specific settings check for the likes of VMA
  mobileFirst: boolean;

  // Introduced on OCBC to check if the WL has multiple points partner
  multiplePointsPartner: boolean;

  // Introduced on OCBC to allow the banner link to open on current window
  bannerTargetWindow: string;

  // default currency display setting
  // this is so we can only have one area where to set what to display on prices
  // only implemented on maps for now and flights
  defaultCurrencyDisplay: "code" | "symbol";

  // Introduced on FAB to allow page reload and bootstrap for coupon usage update
  reloadAfterCheckout: boolean;

  // Introduced for VISA and OCBC to Show Better Rates only for VISA and OCBC control
  showBetterRatesOnly: boolean;

  // Introduced on VMA to display wl specific search loading message
  customResultLoadingMessage: string;

  // Introduce on VMA to restrict open links into new tab
  openInTargetTab: OpenInTargetTabType;

  // Introduced first after new hotel search location component refactor
  blacklistedDestinations: string[];

  // Cookie life in days :)
  cookieLifeinDays: number;

  // Manage booking config with regards modal opening
  manageBooking: {
    cancellationAlertHeaderText: string;
    cancellationAlertBodyText: string
    detailedCancellationModal: boolean;
  };

  // First Introduced in Ascendago flights
  departuresOnly: boolean;

  // Introduced in bounty to control the sticky-header height better in
  // hotel details
  getStickyHeaderHeight: () => number;

  // Introduced for UOB, to add maximum duration in days after
  // the checking day is selected
  maxBookingDays: number;

  // Refactor soon move all checkout settings here
  checkoutSettings: {
    initialCountryOption?: string;
    insufficientPointsModalCheck: boolean;
    addNewCardText?: string;
  };

  wlTranslationPath: string;

  ccValidationUseCardErrorModal: boolean;

  // wl's that uses Landing Page tiles component
  // defaulted to true as we will be using it moving forward
  newLandingPageTiles: boolean;

  // Wl's Travel-Breakdown flex-direction
  isTravelBreakdownFlexDirectionDefault: boolean;

  // Update start check-in date
  startCheckInDate: number;
  startCheckOutDate: number;

  // First introduced in anz to update destination tile date
  destinationTileCheckinDaysLater: number;
  destinationTileCheckoutDaysAfter: number;

  // Enable user action tracking
  enableUserActionTracking: boolean;

  // Wl's material implementation checker
  materialImplementation: boolean;

  // First introduced in vma to use highResolutionUrl room image
  useHighResolutionUrl: boolean;

  // Cars booking page config
  carsBookingPage: {
    confirmedIcon: string;
    pendingIcon: string;
    pendingEmailInText: string;
    bookingReferenceText: string;
    nonRefundableText: string;
    viewManageBookingText: string;
    // Introduced on cars refactor on success page
    hasCarsPdfDownload: boolean;
  };

  // First introduced to hyundai
  hideCheckoutFormPostalCode: boolean;

  e2eWhitelabel: boolean;

  // Introduced on VMA v2 where we need to always return max points regardless of the slider
  maxEarnOnRedeemPoints: boolean;

  // First introduced in vma redeem to display cash amount before points in price boxes
  showCashBeforePoints: boolean;

  // First introduced in vma redeem to toggle different sections in hotel confirmation/success page
  hotelBookingDetailToggles: {
    // Shows a "-" when room remarks is empty
    showEmptyRoomRemarks: boolean;

    // Shows the room label beside the number
    showRoomsLabel: boolean;

    // Shows the night label beside the number
    showNightsLabel: boolean;

    // Shows the label Adult and Children
    showGuestsSummary: boolean;
  };

  sortSettings: {
    // WL which has enabled 100% cash on redemption. First created for OCBC
    canUseFullCashOnRedemption: boolean;

    // Introduced in hyundai to remove sort by price from the list of filters.
    canUseFullPointsOnRedemption: boolean;

    // Introduced in Hyundai
    // prevent display of points sort when slider reached zero cash percent
    showPointsSortInFullPoints: boolean;

    // Introduced in BNZ
    // we need to add prioritisePermittedOptions since not all WLs can 100% fullcash,
    // so we need to implement permittedOptions. We need to refactor updating of options in the future.
    prioritisePermittedOptions: boolean;
  };

  customFlightsTimeLabel: string;

  customFlightsDateTimeLabel: string;

  multipleAirlineLogoUrl: string;

  // show cancellation fee text
  alwaysShowCancellationFee: boolean;

  // cancellation fee text displayed on multiple pages
  cancellationFeeText: {
    details: string;
    checkout: string;
    manageBookings: string;
    success: string;
  };

  // Introduced for Hyundai. We check for the e2e user attribute account_status
  // when this flag is set to true. If the account status is 'blocked', We show modal
  // and prevent the user from moving further.
  userIneligibilityCheck: boolean;

  // Introduced in VMA
  // In results page filter panel, VMA doesn't want redemption range slider to shown at all
  hideRedemptionRangeSlider: boolean;

  // Introduced in BNZ
  alternateSchemeSettings: {
    checkAlternateScheme: boolean;
    userCustomDataEndpoint?: string;
    redirectScheme?: string;
    redirectSchemeUrl?: string;
  };

  // Point Cash Slider Settings
  pointsCashSliderSettings: PointsCashSliderSettings;

  // First introduced in BNZ
  // When this flag is set to true, we assume that users must be logged in before entering our booking platform
  // As such, login button opens a login modal with a message reminding users to login instead of an actual login form
  loginEndpointOpenLoginModal: boolean;

  // First Introduced in vma
  // For handling the custom implementation of error message on flights search
  customFlightsSearchErrorMessage: { origin?: string, destination?: string };

  // First introduced in BNZ
  customFlightsTermsAndConditionsModalTitle: string;

  // First introduced in BNZ to use shorter airport texts (only the airport codes) in modify search bar mobile
  onlyUseAirportCodesinModifySearchMobile: boolean;

  // Used to toggle between Stripe Sources API (default) and Stripe Payment Intents API
  stripePaymentIntentsEnabled: boolean;

  stripePublishableKey: {
    production: string;
    test: string;
  }

  // Used to toggle loyalty program terms and condition checkbox
  showLoyaltyProgramTnC: boolean;

  // First introduced in vma
  // For handling custom banner images
  // Dates starttime and endTime should be in utc
  // EX Date.now() (unix timestamp)
  mastheadBannerSettings: {
    landing_page_banner: {
      hotels?: string,
      cars?: string,
      flights?: string,
    },
    startTime?: number,
    endTime?: number,
    couponCodeText?: string,
    headline?: string,
    subheadline?: string,
    tncheadline?: string,
    showTncheadline: boolean,
  };

  // Prismic Implementation settings control
  prismicSettings: {
    enabled: boolean,
    faqs?: string,
    terms?: string,
    privacyPolicy?: string,
  };

  // First introduced in OCBC for revamped
  // confirmation page to show TF statuses
  useTravelfusionConfirmationPage: boolean;
}

const settings: AppSettings = {
  displayBookWithConfidence: true,
  customCheckoutButtonImage: "",
  customPaymentLoadingImage: "",
  showVatInfo: false,
  carsListBtnLabel: "Book Now",
  onlyShowVisaCardLogo: false,
  poweredByAscenda: false,
  displayDinersOptions: false,
  customPaymentLogo: "",
  includeUnavailableInTotalCount: true,
  showPerNightText: false,
  payWithPoints: {
    roundToNearest: 100,
    roundToNearestByTravelType: {
      hotels: null,
      cars: null,
      flights: null,
    },
    earnOnBurn: false,
    roundDirection: "up",
  },
  customLoadingSearch: false,
  hotelDetailsTemplateConfig: {
    inlinePointsLabel: false,
    roomItemEarnLabel: "",
    showBgPageColor: false,
    earnRewardLabel: "results.earn_at_least",
    doubleMobileRoomPricesButton: false,
    showPointCashSliderRoomList: true,
    showExtraHeadingsRoomList: false,
    showGroupedActions: true,
    showCurrencyIcon: false,
    breakfastIconUrl: "/icons/Breakfast.png",
    noBreakfastIconUrl: "/icons/NoBreakfast.png",
    earnOnRedeem: false,
  },
  carDetailsTemplateConfig: {
    carEquipPriceNote: "",
  },
  turnOnManualCancellation: false,
  allowCancelRedemptionBooking: false,
  showCancelPolicyOnRedeem: false,
  showCancelPolicyOnEarn: true,
  carNonRefundablePolicy: true,
  displayBestDealBadge: false,
  basePointsRatio: 0,
  displayNonBookableMarketRates: false,
  insufficientPointsCheck: false,
  policyNoteOnDetailsPage: "",
  checkUserStillLoggedin: false,
  restrictedLocales: ["he", "tlh"],
  reloadOnSearch: false,
  reloadOnQuickSearch: false,
  hideCarsCheckoutPayment: false,
  customBookingOptionTabs: false,
  showMiniMap: false,
  hotelsPerPage: 8,
  displayDiscoverLogo: false,
  displayJcbLogo: false,
  displayDinersLogo: false,
  showGulfAlert: true,
  enableSelectionOfDates: false,
  supportedCards: {
    amex: ["AED", "AUD", "BRL", "CAD", "CNY", "EUR", "GBP", "HKD", "ILS",
      "INR", "JPY", "KRW", "MYR", "QAR", "RUB", "SAR", "SGD", "THB",
      "TRY", "TWD", "USD", "ZAR"],
    discover: ["BRL"],
    diners: ["BRL"],
    elo: ["BRL"],
    aura: ["BRL"],
    hipercard: ["BRL"],
    jcb: ["JPY"],
  },
  mapboxAPIKey: "pk.eyJ1IjoianVueTRuZyIsImEiOiIyZTkxM2ViNTk3ZDI2N2VmNzVjZDVlZDU0NzQ0ODQyMiJ9.z6e9MUTtICeK4JeuvFDe-g",
  showMarketRates: true,
  enableChildrenSearch: false,
  childrenAgeList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  checkoutRedirectToBookingsPath: true,
  userGulfResident: "AE",
  pointsPartnerIconVisibility: true,
  sessionCounter: {
    isEnabled: false,
    sessionTime: 18 * 60 * 1000,
    countdownTime: 2 * 60 * 1000,
    pollingInterval: 1 * 60 * 1000,
  },
  usDateFormat: false,
  displayPurchaseAccessAndCancelText: false,
  ratingArray: [5, 4, 3, 2, 1],
  preFilterUnavailableHotels: false,
  checkoutPagePlaceholderText: {
    firstName: "",
    lastName: "",
  },
  redirectMarketRates: true,
  showCashSlider: true,
  landingPageUrl: {},
  mapboxHotelMarkerColor: "#105399",
  mapboxHotelClusterColor: "rgba(38,168,207,0.6)",
  mapboxHotelClusterCounterTextColor: "#000000",
  maximumAvailableBookingMonths: 12,
  gauid: "UA-54613553-1",
  displayFeatures: true,
  cdnProcessingURL: "https://www.kaligo.com",
  settingBasedColor: false,
  maximumGuestsMessage: {
    showMessage: false,
    maximumGuests: 0,
  },
  useProductType: false,
  supportedProductTypes: [],
  roomGuestParameters: {
    labelOnlyRoomCounter: false,
    hasRoomGuestCloser: false,
    labelOnlySummaryText: false,
  },
  destinationFieldPlaceHolder: "Destination",
  storeCreditCard: false,
  showCurrencyInFilterSliders: true,
  limitedCountryCityOptions: false,
  hasCouponCode: false,
  customFlightIcon: false,
  showFlightSelectionState: false,
  flightSegmentArrivalDisplay: false,
  hasMultiplePaymentMethods: false,
  defaultAirlineSearchCode: "MSP",
  hermesConfig: {
    timeout: 600,
    promptTimeout: 120,
    sessionCheckIntervalInSeconds: 30,
    userAuthOptions: {
      authBaseUrl: "",
      clientId: "",
    },
  },
  logoutUrl: "/logout",
  canSelectDaysOfPreviousAndNextMonth: false,
  defaultPaymentMethod: PaymentMethod.ADD_CARDS,
  hasCustomflightSummaryHeaderText: false,
  customFlightLineIconUrl: "/linedots.png",
  dateIcon: "date-icon.png",
  flightsEnabledSortingOptions: [FlightSortType.PRICE_LOHI, FlightSortType.PRICE_HILO,
  FlightSortType.DURATION_LOHI, FlightSortType.DURATION_HILO, FlightSortType.OUTBOUND_LOHI,
  FlightSortType.OUTBOUND_HILO, FlightSortType.STOPS_LOHI],
  defaultTravelTypeInHomePage: TravelType.HOTELS,
  defaultCountryInCheckout: {
    hasDefaultCountry: false,
    defaultCountryCode: "SG",
  },
  iconFormat: "png",
  loginModalConfig: {
    loginModalWindowClass: LoginModalWindowClassOption.DEFAULT_CLASS,
    loginModalSize: LoginModalSizeOption.DEFAULT_SIZE,
  },
  contactUsConfig: {
    contactUsHeadingText: "We'd love to hear from you!",
    contactUsEmailText: false,
    contactUsParagraphText: "Our quick & friendly service team monitors messages 24/7 for urgent matters, and also looks forward to receiving your general inquiries and feedback.",
  },
  tenant: Tenant.KALIGO,
  tenantFullName: Tenant.KALIGO,
  requireLoginOnConfirmationPage: false,
  checkoutFormScrollOffset: 50,
  resultsPageScrollOffset: {
    desktop: 0,
  },
  mobileResultPopupConfig: {
    filterIconSource: "",
    sortIconSource: "",
    mapIconSource: "",
  },
  dateFormat: MomentDateType.LL,
  mobileFirst: false,
  multiplePointsPartner: false,
  bannerTargetWindow: "_blank",
  defaultCurrencyDisplay: "code",
  reloadAfterCheckout: true,
  showBetterRatesOnly: false,
  customResultLoadingMessage: null,
  openInTargetTab: {
    allLinksTarget: LinkTargetType.SELF,
    checkoutInNewTab: false,
    markerPopUp: LinkTargetType.BLANK,
  },
  blacklistedDestinations: [],
  cookieLifeinDays: 30,
  manageBooking: {
    cancellationAlertHeaderText: "",
    cancellationAlertBodyText: "my_bookings.cancel_booking.are_you_sure",
    detailedCancellationModal: false,
  },
  getStickyHeaderHeight: () => 0,
  maxBookingDays: null,
  checkoutSettings: {
    insufficientPointsModalCheck: false,
    addNewCardText: "Credit/debit card",
  },
  wlTranslationPath: null,
  ccValidationUseCardErrorModal: false,
  newLandingPageTiles: true,
  isTravelBreakdownFlexDirectionDefault: true,
  startCheckInDate: 10,
  startCheckOutDate: 15,
  destinationTileCheckinDaysLater: 14,
  destinationTileCheckoutDaysAfter: 3,
  enableUserActionTracking: false,
  materialImplementation: false,
  useHighResolutionUrl: false,
  carsBookingPage: {
    confirmedIcon: "/icons/generic-confirmed-solid.svg",
    pendingIcon: "/icons/generic-pending-solid.svg",
    pendingEmailInText: "purchase.pending.email_in_48h",
    bookingReferenceText: "Booking reference",
    nonRefundableText: "booking_detail.cancellation_policy.non_refundable",
    viewManageBookingText: "View manage booking",
    hasCarsPdfDownload: false,
  },
  hideCheckoutFormPostalCode: false,
  e2eWhitelabel: false,
  maxEarnOnRedeemPoints: false,
  showCashBeforePoints: false,
  hotelBookingDetailToggles: {
    showEmptyRoomRemarks: false,
    showRoomsLabel: false,
    showNightsLabel: false,
    showGuestsSummary: false,
  },
  sortSettings: {
    canUseFullCashOnRedemption: false,
    canUseFullPointsOnRedemption: false,
    showPointsSortInFullPoints: true,
    prioritisePermittedOptions: false,
  },
  customFlightsTimeLabel: "h:mm A",
  customFlightsDateTimeLabel: "D MMM YYYY | hh:mmA",
  multipleAirlineLogoUrl: "/flights/icn-multiflights.png",
  alwaysShowCancellationFee: false,
  cancellationFeeText: {
    details: "txt.free_cancellation_except_service_fee",
    checkout: "cancellation_policy_service_fee",
    manageBookings: "cancellation_policy_service_fee",
    success: "cancellation_policy_service_fee",
  },
  hideRedemptionRangeSlider: false,
  userIneligibilityCheck: false,
  alternateSchemeSettings: {
    checkAlternateScheme: false,
  },

  pointsCashSliderSettings: {
    alwaysShowPointsSlider: false,
    pointSliderDefaultValue: 0,
    incrementPointsSliderCounter: true,
    hasCustomSliderLabel: false,
    ceilingLabelPrefix: "txt.minimum\n",
    invertedPointsCashSlider: false,
    sliderLabel: "txt.combine",
    sliderRightLabel: "txt.cash",
    sliderLeftLabel: "Points",
  },
  loginEndpointOpenLoginModal: false,
  customFlightsSearchErrorMessage: {
    origin: null,
    destination: null,
  },
  customFlightsTermsAndConditionsModalTitle: "",
  onlyUseAirportCodesinModifySearchMobile: false,
  stripePaymentIntentsEnabled: false,
  stripePublishableKey: {
    production: "pk_live_yU30Pd1u2Tp4WWTJjg0euqfh",
    test: "pk_test_5AaYXwkcnyL1mGmllELOFdqO",
  },
  showLoyaltyProgramTnC: false,
  mastheadBannerSettings: {
    landing_page_banner: {
      hotels: null,
      cars: null,
      flights: null,
    },
    startTime: null,
    endTime: null,
    couponCodeText: null,
    headline: null,
    subheadline: null,
    showTncheadline: true,
  },
  prismicSettings: {
    enabled: false,
  },
  departuresOnly: false,
  useTravelfusionConfirmationPage: false,
};

angular.module("BookingApp").constant("AppSettings", settings);
