import { CouponCodeBannerController } from "./coupon-code-banner.controller";

export class CouponCodeBannerComponent {
  controller: any = CouponCodeBannerController;
  template: string = `
    <div class="coupon-code-banner-container" ng-class="{'is-sticky': $ctrl.isSticky}" ng-if="$ctrl.couponMessage">

      <div class="coupon-message">
        <i class="coupon-icon"></i>
        <span ng-bind-html="$ctrl.couponMessage"></span>
      </div>
    </div>
  `;
}

angular.module("BookingApp").component("couponCodeBanner", new CouponCodeBannerComponent());
