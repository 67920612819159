import { AppSettings } from "booking_app/values/app-settings";
import { CurrenciesService } from "./currencies.service";
import { GlobalStateService } from "./global-state.service";
import { PointsCashShareService } from "./points-cash-share.service";
import { BonusProgramType, CarItem, HotelItem } from "booking_app/types";
import { FlightsItinerary } from "booking_app/models";
import { CurrentPage } from "booking_app/types";

export class PayWithPointsCashService {
  static $inject = [
    "$rootScope",
    "$filter",
    "$translate",
    "AppSettings",
    "CurrenciesService",
    "FormatService",
    "GlobalStateService",
    "PointsCashShareService",
  ];

  private isInitialized: boolean = false;

  constructor(
    private $rootScope: any,
    private $filter: any,
    private $translate: any,
    private appSettings: AppSettings,
    private currenciesService: CurrenciesService,
    private formatService: any,
    private globalStateService: GlobalStateService,
    private pointsCashShareService: PointsCashShareService,
  ) {}

  public initializePayWithPointsCash(): void {
    if (this.isInitialized) {
      return;
    }
    this.pointsCashShareService.initializePointsCashShare();
    this.isInitialized = true;
  }

  public toNearest(value: number): number {
    const { travelType } = this.globalStateService;
    const { productType } = this.$rootScope.globalState;
    const { payWithPoints } = this.appSettings;
    const currentPage = this.globalStateService.currentPage;

    const defaultRounding = payWithPoints.roundToNearest;
    const couponSettings = payWithPoints.roundToNearestByCouponCodeSettings || {};
    const couponRounding = couponSettings[travelType] && couponSettings[travelType][productType];

    const shouldApplyCouponRounding = this.$rootScope.couponCodeApplied && couponRounding && currentPage === CurrentPage.CHECKOUT;

    const rounding = shouldApplyCouponRounding ? couponRounding : defaultRounding;

    return payWithPoints.roundDirection === "down"
      ? Math.floor(value / rounding) * rounding
      : Math.ceil(value / rounding) * rounding;
  }

  public toTwoDecimalPlace(value: number): number {
    return parseFloat(this.currenciesService.adjustDecimals(value, 2));
  }

  public formatCurrency(value: number): number {
    if (this.$rootScope.selectedCurrency.decimalPlace === 0) {
      return Math.ceil(value);
    } else {
      return this.toTwoDecimalPlace(value);
    }
  }

  public minPoint(maxPoints: number): number {
    const travelType: string = this.globalStateService.travelType;
    const tiersLength: number = this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType).length;
    let minPoints: number = maxPoints * this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)[tiersLength - 1];

    minPoints = Math.max(this.$rootScope.pointsPartner.minPoints, minPoints);
    return this.toNearest(minPoints);
  }

  public calculatePointsCashToPay(hotelItem: HotelItem): HotelItem {
    const duration: number = (this.$rootScope.duration * this.$rootScope.roomCount);
    return this.calculatePointsCash(hotelItem, duration) as HotelItem;
  }

  public calculatePointsCashToPayForCar(carItem: CarItem, startDate: string, endDate: string): CarItem {
    const duration: number = this.$filter("getNumberOfCarDays")(startDate, endDate);
    return this.calculatePointsCash(carItem, duration) as CarItem;
  }

  public useMinPoints(item: HotelItem | CarItem | FlightsItinerary): boolean {
    return this.calculatedPoints(item.max_points_payment) < this.$rootScope.pointsPartner.minPoints;
  }

  public cashToPayAfterMinPointsValue(maxCash: number): number {
    return this.currenciesService.convertFromUsd(maxCash - this.$rootScope.pointsPartner.minPointsCashValueInUsd);
  }

  public calculateAdjustedFeeBreakdown(item: HotelItem): HotelItem {
    // This is a temporary fix - should be removed as we introduce stripe processing fee on BE
    // * Note: baseRate here is calculated by taking original total - includedTaxesAndFeesTotal for 0.01 rounding issues
    // * this should be fixed as we introduce processing fees on the BE
    if (!item) {
      return; // required to prevent error on checkout page.
    }
    const originalTotalCharge = this.adjustFeeBreakdown(item.base_rate + item.included_taxes_and_fees_total);
    item.includedTaxesAndFees = item.included_taxes_and_fees.map((taxAndFeeItem) => (
      {id: taxAndFeeItem.id, amount: this.adjustFeeBreakdown(taxAndFeeItem.amount)}
    ));
    item.includedTaxesAndFeesTotal = this.adjustFeeBreakdown(item.included_taxes_and_fees_total);
    item.baseRate = originalTotalCharge - item.includedTaxesAndFeesTotal;
    return item as HotelItem;
  }

  public calculatePointsCash(item: HotelItem | CarItem, duration: number): HotelItem | CarItem {
    if (!item) {
      return; // required to prevent error on checkout page.
    }

    if (item.max_points_payment && item.max_points_payment > 0) {
      // duplicate array for invertedPointsCashSlider condition
      let pointsDiscountTier: number[] = [...item.points_non_fixed_discounts_by_tier];
      if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
        pointsDiscountTier = pointsDiscountTier.reverse();
      }
      const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];
      item.points_payment = this.pointsToPay(
        item.base_points_payment,
        nonFixedDiscount,
        item.points_fixed_discount);

      item.points_payment_per_night = Math.ceil(item.points_payment / duration);
      item.original_points_payment = this.pointsToPay(item.base_points_payment);
      item.original_points_payment_per_night = Math.ceil(item.original_points_payment / duration);
      item.minPoint = this.minPoint(item.max_points_payment);
    }

    if (item.max_cash_payment ||
        (item.max_cash_payment_by_tier && item.max_cash_payment === 0)) {
      let calculatedCash: number;
      let calculatedBaseCash: number;
      if (this.useMinPoints(item)) {
        calculatedCash = this.cashToPayAfterMinPointsValue(item.max_cash_payment);
        calculatedBaseCash = this.cashToPayAfterMinPointsValue(item.base_cash_payment);
      } else {
        // duplicate array for invertedPointsCashSlider condition
        let cashDiscountTier: number[] = [...item.cash_non_fixed_discounts_by_tier];
        if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
          cashDiscountTier = cashDiscountTier.reverse();
        }
        const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];
        calculatedCash = this.cashToPay(
          item.base_cash_payment,
          nonFixedDiscount,
          item.cash_fixed_discount);

        calculatedBaseCash = this.cashToPay(item.base_cash_payment);
      }

      if (calculatedCash > 0) {
        item.cash_payment = this.formatCurrency(calculatedCash);
        item.original_cash_payment = this.formatCurrency(calculatedBaseCash);
        item.cash_payment_per_night = this.formatCurrency(item.cash_payment / duration);
        item.original_cash_payment_per_night = this.formatCurrency(item.original_cash_payment / duration);
      } else {
        item.original_cash_payment = 0;
        item.original_cash_payment_per_night = 0;
        item.cash_payment = 0;
        item.cash_payment_per_night = 0;
      }

      item.earnOnBurnValue = this.earnOnRedeemPoints(item, calculatedCash);
    }

    return item;
  }

  public pointsToPay(basePoints: number, nonFixedDiscountPoints: number = 0, fixedDiscountPoints: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    if (!this.$rootScope.pointsPartner || !this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)) {
      return this.toNearest(basePoints - nonFixedDiscountPoints - fixedDiscountPoints);
    }
    if (!this.$rootScope.pointsPartner.minPoints) {
      return this.toNearest(
        this.calculatedPoints(basePoints, nonFixedDiscountPoints, fixedDiscountPoints),
      );
    }
    return this.toNearest(
      Math.max(
        this.calculatedPoints(basePoints, nonFixedDiscountPoints, fixedDiscountPoints),
        this.$rootScope.pointsPartner.minPoints,
      ),
    );
  }

  public cashToPay(baseCash: number, nonFixedDiscountCash: number = 0, fixedDiscountCash: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    let maxCash: number;
    if (this.$rootScope.pointsPartner &&
      this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType) &&
      this.pointsCashShareService.pointsCashShare) {
        maxCash = this.calculatedCash(baseCash, nonFixedDiscountCash, fixedDiscountCash);
    } else {
      maxCash = baseCash - nonFixedDiscountCash - fixedDiscountCash;
    }
    return this.toTwoDecimalPlace(
      this.currenciesService.convertFromUsd(maxCash),
    );
  }

  public earnOnRedeemPoints(item: HotelItem | CarItem, calculatedCash: number): number {
    // TODO: implement in V2
    const travelType: string = this.globalStateService.travelType;
    const pointsCashShare = this.pointsCashShareService.pointsCashShare.value;
    const cashPaymentTier = this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType)[pointsCashShare];
    const pointsPaymentTier = this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)[pointsCashShare];

    if (this.appSettings.maxEarnOnRedeemPoints) {
      const paid_in_cash_value = item.max_earn_on_redeem_points * cashPaymentTier;
      const remaining_cash_value = item.max_earn_on_redeem_points * pointsPaymentTier;

      return Math.ceil(paid_in_cash_value + remaining_cash_value);
    }

    if (calculatedCash <= 0) {
      return 0;
    }

    if (this.useMinPoints(item)) {
      return item.max_earn_on_redeem_points / this.currenciesService.convertFromUsd(item.max_cash_payment) *
        this.cashToPayAfterMinPointsValue(item.max_cash_payment);
    } else {
      return Math.ceil(
        item.max_earn_on_redeem_points *
          this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType)[pointsCashShare],
      );
    }
  }

  public getPriceBeforeDiscount(item: HotelItem | CarItem | FlightsItinerary): string {
    const originalPointsPayment: number = this.pointsToPay(
      item.base_points_payment,
    );
    let originalCashPayment: number;
    let priceAfterDiscount: string = "";

    if (this.useMinPoints(item)) {
      originalCashPayment = this.cashToPayAfterMinPointsValue(item.base_cash_payment);
    } else {
      originalCashPayment = this.cashToPay(item.base_cash_payment);
    }

    if (item && originalPointsPayment > 0) {
      const pointsPayment: number = this.formatService.number(originalPointsPayment, this.$rootScope.selectedLocale, 0);
      priceAfterDiscount = `${pointsPayment} ${this.$translate.instant(this.$rootScope.pointsPartner.shortCurrency)}`;
    }

    if (item && originalCashPayment > 0) {
      if (originalPointsPayment > 0) {
        priceAfterDiscount = `${priceAfterDiscount} + `;
      }
      const cashPayment: number = this.formatService.number(originalCashPayment, this.$rootScope.selectedLocale, 2);
      const currency: string = this.$filter("codeSymbolFmt")(this.$rootScope.selectedCurrency.code);
      priceAfterDiscount = `${priceAfterDiscount} ${currency} ${cashPayment}`;
    }

    return priceAfterDiscount;
  }

  public hasDiscountedPrice(item: HotelItem | CarItem | FlightsItinerary): boolean {
    return item &&
      ((item.base_points_payment && item.base_points_payment !== item.max_points_payment) ||
        (item.base_cash_payment && item.base_cash_payment !== item.max_cash_payment));
  }

  private calculatedPoints(
    basePoints: number,
    nonFixedDiscountPoints: number = 0,
    fixedDiscountPoints: number = 0): number {
    const travelType: string = this.globalStateService.travelType;
    const pointsCashShare = this.pointsCashShareService.pointsCashShare.value;

    return (basePoints *
      this.$rootScope.pointsPartner.pointsPaymentTiersBy(travelType)[pointsCashShare]) -
      nonFixedDiscountPoints - fixedDiscountPoints;
  }

  private calculatedCash(
    baseCash: number,
    nonFixedDiscountCash: number = 0,
    fixedDiscountCash: number = 0,
  ): number {
    const travelType: string = this.globalStateService.travelType;
    const pointsCashShare = this.pointsCashShareService.pointsCashShare.value;
    return (
      baseCash *
      this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType)[pointsCashShare]
    ) - nonFixedDiscountCash - fixedDiscountCash;
  }

  private adjustFeeBreakdown(baseCash: number): number {
    // gets last payment tier to multiply and converts to usd
    const travelType: string = this.globalStateService.travelType;
    const adjustment: number = this.$rootScope.pointsPartner.cashPaymentTiersBy(travelType).at(-1);

    // if there's no saved adjustment, we return the pre-adjusted base value
    return this.toTwoDecimalPlace(
      this.currenciesService.convertFromUsd(
        baseCash * (adjustment === 0 ? 1 : adjustment),
      ),
    );
  }
}

angular.module("BookingApp").service("PayWithPointsCashService", PayWithPointsCashService);
