import { BookingTransactionsResult } from "booking_app/models";
import { PayAnyoneService } from "booking_app/services/payments/pay-anyone.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { Bindings, LoadingMessage, PaymentChannel, TransactionStatus, TravelType } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { PaymentService } from "booking_app/services/payment";
import { AdyenFormService } from "booking_app/services/adyen-form.service";
import { StripePaymentIntentService } from "booking_app/services/stripe-payment-intent.service";
import { ModalManager } from "booking_app/components/common/modal-manager";

export class CheckoutStatusService {
  static $inject = [
    "$rootScope",
    "$timeout",
    "$location",
    "$window",
    "$routeParams",
    "AppSettings",
    "BookingTransactionsService",
    "CarsState",
    "GlobalStateService",
    "PaymentService",
    "PayAnyoneService",
    "AdyenFormService",
    "StripePaymentIntentService",
    "ModalManager",
  ];

  loadingMessage: string;
  isLoading: boolean;
  errorCode: string;
  private transactionsService: any;
  private bookingTransactionId: string;

  constructor(
    private $rootScope: any,
    private $timeout: any,
    private $location: any,
    private $window: any,
    private $routeParams: any,
    private appSettings: AppSettings,
    private BookingTransactionsService: any,
    private carsState: any,
    private globalStateService: GlobalStateService,
    private paymentService: PaymentService,
    private payAnyoneService: PayAnyoneService,
    private adyenFormService: AdyenFormService,
    private stripePaymentIntentService: StripePaymentIntentService,
    private modalManager: ModalManager,
  ) {
    this.resetState();
  }

  resetState() {
    this.loadingMessage = LoadingMessage.PAYMENT_IN_PROGRESS;
    this.isLoading = false;
    this.errorCode = null;
    this.$rootScope.couponCodeApplied = false;
  }

  checkTransactionStatus(transactionId: string): void {
    this.bookingTransactionId = transactionId;
    this.transactionsService = new this.BookingTransactionsService(transactionId);
    this.isLoading = true;
    this.pollTransactionStatus();
  }

  private pollTransactionStatus() {
    this.transactionsService.checkStatus().then((res: BookingTransactionsResult) => {
      switch (res.status) {
        case TransactionStatus.SUCCESS:
          this.handleSuccess(res);
          break;
        case TransactionStatus.PAYMENT_STARTED:
          this.startLoadingWithMessage(LoadingMessage.PAYMENT_IN_PROGRESS);
          this.handlePaymentStarted(res);
          break;
        case TransactionStatus.REQUIRES_USER_AUTHENTICATION:
          this.startLoadingWithMessage(LoadingMessage.AUTHENTICATION_IN_PROGRESS);
          this.redirectUserToGateway(res);
          break;
        case TransactionStatus.PAYMENT_PENDING:
          this.startLoadingWithMessage(LoadingMessage.PAYMENT_IS_PENDING);
          this.pollTransactionStatus();
          break;
        case TransactionStatus.PAYMENT_CONFIRMED:
          this.startLoadingWithMessage(LoadingMessage.PAYMENT_CONFIRMED_BOOKING_NOW);
          this.pollTransactionStatus();
          break;
        default:
          this.handleUnknownStatus(res);
      }
    });
  }

  private handleSuccess(res: BookingTransactionsResult): void {
    this.payAnyoneService.resetPayAnyone();
    this.trackBooking(res.booking_id);
    this.$timeout(() => {
      switch (this.globalStateService.travelType) {
        case TravelType.HOTELS:
          this.hotelBookingSuccessPage(res);
          break;
        case TravelType.CARS:
          this.carsBookingSuccessPage(res);
          break;
        case TravelType.FLIGHTS:
          this.flightsBookingSuccessPage(res);
          break;
      }
    });
  }

  private hotelBookingSuccessPage(res: BookingTransactionsResult): void {
    if (this.appSettings.checkoutRedirectToBookingsPath) {
      const successPageUrl: string = `/bookings/${res.booking_reference}?access_token=${res.access_token}`;
      if (this.appSettings.reloadAfterCheckout) {
        this.$window.location.href = this.appendPartnerId(successPageUrl);
      } else {
        this.$location.url(successPageUrl);
      }
    } else {
      this.$location.url(`/purchases/${res.booking_id}?access_token=${res.access_token}`);
    }
  }

  private carsBookingSuccessPage(res: BookingTransactionsResult): void {
    this.carsState.clear();
    const successPageUrl: string = `/cars/bookings/${res.booking_reference}?access_token=${res.access_token}`;
    if (this.appSettings.reloadAfterCheckout) {
      this.$window.location.href = this.appendPartnerId(successPageUrl);
    } else {
      this.$location.url(successPageUrl);
    }
  }

  private flightsBookingSuccessPage(res: BookingTransactionsResult): void {
    const successPageUrl: string = `/flights/bookings/${res.booking_reference}?access_token=${res.access_token}`;
    if (this.appSettings.reloadAfterCheckout) {
      this.$window.location.href = this.appendPartnerId(successPageUrl);
    } else {
      this.$location.url(successPageUrl);
    }
  }

  private handlePaymentStarted(res: BookingTransactionsResult): void {
    switch (res.payment_channel) {
      case PaymentChannel.ADYEN_ALIPAY:
        this.redirectUserToAlipay(res);
        break;
      case PaymentChannel.ADYEN_UNIONPAY:
      case PaymentChannel.ADYEN_DINERS:
      case PaymentChannel.ADYEN_JCB:
        this.handleAdyenCardStart(res);
        break;
      case PaymentChannel.CHECKOUT_COM:
      case PaymentChannel.STRIPE:
        this.handleStripeStart(res);
        break;
      case PaymentChannel.PAY_ANYONE:
        this.handlePayAnyoneStart(res);
        break;
      case PaymentChannel.STRIPE_PAYMENT_INTENTS:
        this.handlePaymentIntentStart(res);
        break;
      default:
        this.redirectUserToGateway(res);
        break;
    }
  }

  private handleStripeStart(res: BookingTransactionsResult): void {
    this.paymentService.start(res.gateway_url)
      .then(() => this.pollTransactionStatus())
      .catch((e) => {
        this.isLoading = false;
        this.errorCode = e.error;
      });
  }

  private handlePayAnyoneStart(res: BookingTransactionsResult): void {
    const showLoading = this.payAnyoneService.start(res, this.bookingTransactionId);
    this.isLoading = showLoading;
    this.pollTransactionStatus();
  }

  private handleAdyenCardStart(res: BookingTransactionsResult): void {
    if (res.params.action) {
      this.$rootScope.$on("adyen-3ds-completed", (_event) => {
        this.isLoading = true;
        this.pollTransactionStatus();
      });

      this.adyenFormService.handle3dsAction(res.params.action);
      this.$timeout(() => {
        this.isLoading = false;
      }, 2000);
    } else {
      this.pollTransactionStatus();
    }
  }

  private handlePaymentIntentStart(res: BookingTransactionsResult): void {
    if (res.params.payment_intent) {
      this.stripePaymentIntentService.confirmIntentProcess(
        res.params.payment_intent.secret,
        (_response) => {
          // Update BT status payment_confirmed, rerun checkout steps
          // We won't rely on the webhook because:
          // 1. at this point, we are already 100% sure the payment is confirmed, no need to wait for a notification
          // 2. in development, a webhook endpoint is not always setup
          this.transactionsService.updateStatus(TransactionStatus.PAYMENT_CONFIRMED);
          this.pollTransactionStatus();
        },
        (error) => {
          // Update BT status failed
          this.transactionsService.updateStatus(TransactionStatus.FAIL, {
            error: "Payment Failed",
            error_detail: error,
          });
          this.pollTransactionStatus();
        },
        this.bookingTransactionId,
      );
    } else {
      this.pollTransactionStatus();
    }
  }

  private startLoadingWithMessage(msg: string): void {
    this.isLoading = true;
    this.loadingMessage = msg;
  }

  private redirectUserToAlipay(res: BookingTransactionsResult): void {
    this.$window.location.href = res.params.url;
  }

  private redirectUserToGateway(res: BookingTransactionsResult): void {
    this.$window.location.href = res.gateway_url;
  }

  private handleUnknownStatus(res: BookingTransactionsResult): void {
    this.payAnyoneService.resetPayAnyone();
    this.isLoading = false;
    this.errorCode = res.error;
    if (this.globalStateService.isTravelType(TravelType.CARS)) {
      this.carsState.transactionId = "";
    }
  }

  private appendPartnerId(url: string): string {
    if (this.$routeParams.partnerId) {
      return `${url}&partnerId=${this.$routeParams.partnerId}`;
    } else {
      return url;
    }
  }

  private trackBooking(bookingId: string) {
    // track booking
  }
}

angular.module("BookingApp").service("CheckoutStatusService", CheckoutStatusService);
